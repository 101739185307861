import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image, FileText, Briefcase, Settings } from "lucide-react";

const Header = () => {
  const [state, setState] = useState({
    isOpen: false,
    isActive: false,
    isNoti: false,
  });

  return (
    <div className="nav-header mt-10 bg-white shadow-md border-0">
      <div className="px-6 py-4 border-b">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-800">Administrative Page</h1>
          <div className="flex items-center ml-5 space-x-4">
            <Settings className="w-5 h-5 text-gray-500" />
         
          </div>
        </div>
      </div>

  

      <nav className={`navigation ${state.isOpen ? "nav-active" : ""}`}>
        <div className="container px-0">
          <div className="nav-content">
            <div className="nav-wrap bg-white rounded-xl shadow-sm p-4 mb-2">
              <div className="mb-4 px-2">
                <span className="text-sm font-semibold text-gray-600 uppercase tracking-wider">
                  Actions
                </span>
              </div>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/admin/albumcamps"
                    className="flex items-center px-4 py-3 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <Image className="w-5 h-5 text-gray-500 mr-3" />
                    <span className="font-medium">Camps</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/albumevents"
                    className="flex items-center px-4 py-3 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <Image className="w-5 h-5 text-gray-500 mr-3" />
                    <span className="font-medium">EventsOdin</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/blog"
                    className="flex items-center px-4 py-3 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <FileText className="w-5 h-5 text-gray-500 mr-3" />
                    <span className="font-medium">Blog</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/challenge"
                    className="flex items-center px-4 py-3 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <FileText className="w-5 h-5 text-gray-500 mr-3" />
                    <span className="font-medium">Challenges</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/offreemploi"
                    className="flex items-center px-4 py-3 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    <Briefcase className="w-5 h-5 text-gray-500 mr-3" />
                    <span className="font-medium">Offre emploi</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;